import React from 'react';
import PropTypes from 'prop-types';
import { TweenLite } from 'gsap';

import mobileLogo from '../images/Cravath200_Logo_NavBar_Mobile.svg';
import desktopLogo from '../images/Cravath200_Logo_NavBar_Desktop.svg';

import '../styles/header.scss';
import HeaderOverlay from './mobile-overlay';
import InternalLink from './internal-link';
import { ABOUT_PAGE_TITLE } from '../utils/constants';

let picturefill;
if (typeof window !== 'undefined') {
    picturefill = require('picturefill');
}

class Header extends React.Component {
    state = { active: false };

    constructor(props) {
        super(props);

        this.header = null;
    }

    componentDidMount = () => {
        picturefill();
        const { isHomepage } = this.props;
        if (!isHomepage && this.header) {
            TweenLite.set(this.header, {
                y: 0,
                opacity: 1,
            });
        }
    };

    reveal = () => {
        const { isHomepage } = this.props;
        if (isHomepage && this.header) {
            TweenLite.to(this.header, 1, {
                y: 0,
                opacity: 1,
                force3D: true,
                ease: 'Power1.easeOut',
            });
        }
    };

    showOverlay = () => {
        this.setState({ active: true });
    };

    hideOverlay = () => {
        this.setState({ active: false });
    };

    setRef = (element) => {
        this.header = element;
    };

    render() {
        return (
            <>
                <header className="header background-darkBlue js-header" ref={this.setRef}>
                    <div className="header__content">
                        <InternalLink to="/">
                            <picture>
                                <source srcSet={desktopLogo} media="(min-width: 768px)" />
                                <img alt="Cravath 200 Logo" src={mobileLogo} className="header__logo" />
                            </picture>
                        </InternalLink>
                        <div className="header__links">
                            <InternalLink
                                to="/about"
                                className="u-uppercase yellow u-no-mobile type-very-small u-hover-underline"
                            >
                                {ABOUT_PAGE_TITLE}
                            </InternalLink>
                            <div className="header__icons yellow js-header-icon">
                                <span className="icon-menu js-icon-menu" onClick={this.showOverlay} />
                            </div>
                        </div>
                    </div>
                </header>
                <HeaderOverlay {...this.state} hideOverlay={this.hideOverlay} />
            </>
        );
    }
}

Header.propTypes = {
    isHomepage: PropTypes.bool,
};

Header.defaultProps = {
    isHomepage: false,
};

export default Header;

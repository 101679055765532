import ModuleFooting from './module-footing';
import * as React from 'react';
import UpperEyebrow from './upper-eyebrow';
import ModuleHeading from './module-heading';
import ModuleBody from './module-body';

export const StandardTextModule = ({ year, headingClasses, headingText, bodyCopy, moduleClasses }) => {
    return (
        <div className={`module module--bordered ${moduleClasses}`}>
            <UpperEyebrow year={year} />
            <ModuleHeading headingClasses={headingClasses} headingText={headingText} />
            <ModuleBody classes="type-p module__body" bodyCopy={bodyCopy} />
        </div>
    );
};

export const PortraitModule = ({ year, children, headingClasses, headingText, bodyCopy, title, moduleClasses }) => {
    return (
        <div className={`module module--portrait ${moduleClasses}`}>
            <UpperEyebrow year={year} />
            {children}
            <p className="type-small module__title gold u-uppercase u-text-center">{title}</p>
            <ModuleHeading headingClasses={`${headingClasses} u-text-center`} headingText={headingText} />
            <ModuleBody classes="type-p module__body u-text-center" bodyCopy={bodyCopy} />
        </div>
    );
};

export const BioModule = ({ headingText, bodyCopy, title, moduleClasses }) => {
    return (
        <div className={`module module--bio ${moduleClasses}`}>
            <p className="type-bio-eyebrow type-bio-eyebrow--demi module-bio-eyebrow lightGray">{title}</p>
            <h3
                className="type-h2 type-h2--sans gold module-bio-title"
                dangerouslySetInnerHTML={{ __html: headingText }}
            />
            <p className="type-p module__body darkGray" dangerouslySetInnerHTML={{ __html: bodyCopy }} />
        </div>
    );
};

export const IntroModule = ({ headingClasses, headingText, title, moduleClasses }) => {
    return (
        <div className={`module module--intro ${moduleClasses}`}>
            <h2
                className={`${headingClasses} u-text-center darkBlue`}
                dangerouslySetInnerHTML={{ __html: headingText }}
            />
            <p className="type-bio-eyebrow lightGray u-text-center" dangerouslySetInnerHTML={{ __html: title }} />
        </div>
    );
};

export const SpacerModule = () => {
    return <div className={`module module--spacer module--full-width`} />;
};

export const ColumnSpacerModule = () => {
    return <div className={`module module--spacer`} />;
};

export const StandardAssetModule = ({ year, children, headingClasses, headingText, bodyCopy, moduleClasses }) => {
    return (
        <div className={`module ${moduleClasses}`}>
            <UpperEyebrow year={year} />
            {children}
            <ModuleHeading headingClasses={headingClasses} headingText={headingText} />
            <ModuleBody classes="type-p module__body" bodyCopy={bodyCopy} />
        </div>
    );
};

export const WideAssetModule = ({ year, children, headingClasses, headingText, bodyCopy, moduleClasses }) => {
    return (
        <div className={`module module--full-width module--wide-asset module--bordered ${moduleClasses}`}>
            <UpperEyebrow year={year} />
            <ModuleHeading headingClasses={headingClasses} headingText={headingText} />
            {children}
            <ModuleBody classes="type-p module__body" bodyCopy={bodyCopy} />
        </div>
    );
};

export const WideTextModule = ({ year, headingClasses, headingText, bodyCopy, moduleClasses, subheading, footing }) => {
    return (
        <div className={`module module--full-width module--wide-text module--bordered ${moduleClasses} u-text-center`}>
            <UpperEyebrow year={year} />
            <ModuleHeading headingClasses={headingClasses} headingText={headingText} />
            <p className="darkGray type-p module__subheading" dangerouslySetInnerHTML={{ __html: subheading }} />
            <ModuleBody classes="type-h3-mobile--h1-desktop module__body darkBlue" bodyCopy={bodyCopy} />
            <ModuleFooting footing={footing} />
        </div>
    );
};

export const YearModule = ({ year, headingClasses, headingText, bodyCopy, moduleClasses }) => {
    return (
        <div className={`module module--width-2 u-text-center ${moduleClasses}`}>
            <p className="type-big module__year gold">{year}</p>
            <div className="module__right-aligned-content u-text-left">
                <h3 className={headingClasses} dangerouslySetInnerHTML={{ __html: headingText }} />
                <p className="type-p module__body" dangerouslySetInnerHTML={{ __html: bodyCopy }} />
            </div>
        </div>
    );
};

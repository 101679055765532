import * as React from 'react';

const ExternalLink = ({ to, children, newTab, className }) => {
    const linkAttributes = {
        href: to,
        rel: 'noopener noreferrer',
        className,
    };

    if (newTab) {
        linkAttributes.target = '_blank';
    }

    return <a {...linkAttributes}>{children}</a>;
};

export default ExternalLink;

import * as React from 'react';
import { TweenLite, TimelineLite } from 'gsap';

import Layout from '../components/layout';
import AnimatedMedia from '../components/animated-media';

import '../styles/homepage.scss';
import '../styles/animated-logo.scss';
import { playVideo, pauseVideo } from '../utils/media';
import { Picture } from '../components/picture';
import HomepageBackgroundGradient from '../components/homepage-background-gradient';

import animatedLogoCravath from '../images/animated-cravath-logo-cravath.svg';
import animatedLogoSlash from '../images/animated-cravath-logo-slash.svg';
import animatedLogo200 from '../images/animated-cravath-logo-200.svg';
import EraLinks from '../components/era-links';

import eras from '../data/eras.json';

if (typeof Element !== 'undefined' && !Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

const backgroundImage = {
    jpg: 'background_mobile.jpg',
    webp: 'background_mobile.webp',
    mediaQueries: [
        {
            src: 'background_desktop.webp',
            type: 'webp',
            media: 'min-width: 769px',
        },
        {
            src: 'background_desktop.jpg',
            type: 'jpg',
            media: 'min-width: 769px',
        },
    ],
};

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.references = {
            layout: null,
            animatedLogo: null,
            animatedLogoCravath: null,
            animatedLogoSlash: null,
            animatedLogo200: null,
            navLinkYears: null,
            navLinkEras: null,
            subhero: null,
        };

        this.timeline = new TimelineLite({
            paused: true,
            onComplete: this.hideLogo,
        });
    }

    refsExist = () =>
        this.references.layout &&
        this.references.animatedLogo &&
        this.references.animatedLogoCravath &&
        this.references.animatedLogoSlash &&
        this.references.animatedLogo200 &&
        this.references.navLinkYears &&
        this.references.navLinkEras;

    componentDidMount = () => {
        if (this.refsExist()) {
            TweenLite.set(this.references.navLinkYears, {
                opacity: 0,
                x: '-30',
            });
            TweenLite.set(this.references.subhero, {
                opacity: 0,
                x: '-30',
            });
            TweenLite.set(this.references.animatedLogo, {
                visibility: 'visible',
            });
            TweenLite.set(this.references.animatedLogoSlash, {
                scaleY: 0,
                skewX: -20,
            });

            this.timeline
                .add(this.slashReveal())
                .add(this.wordsReveal(), '-=0.65')
                .add(this.logoDisappear(), '+=0.75')
                .add(this.yearsSlide())
                .add(this.subheroSlide())
                .add(this.erasReveal);

            this.timeline.play();
        }
    };

    erasReveal = () => {
        if (this.references.navLinkEras) {
            this.references.navLinkEras.revealEraLinks();
        }
    };

    yearsSlide = () =>
        TweenLite.to(this.references.navLinkYears, 0.7, {
            x: 0,
            opacity: 1,
            visibility: 'visible',
            force3D: true,
            ease: 'Power1.easeOut',
        });

    subheroSlide = () =>
        TweenLite.to(this.references.subhero, 0.7, {
            x: 0,
            opacity: 1,
            visibility: 'visible',
            force3D: true,
            ease: 'Power1.easeOut',
        });

    slashReveal = () =>
        TweenLite.to(this.references.animatedLogoSlash, 0.7, {
            scaleY: 1,
            skewX: -20,
            force3D: true,
            ease: 'Power3.easeInOut',
        });

    wordsReveal = () => [
        TweenLite.to(this.references.animatedLogoCravath, 1.15, {
            skewX: 20,
            x: 0,
            force3D: true,
            ease: 'Power3.easeInOut',
        }),
        TweenLite.to(this.references.animatedLogo200, 1.15, {
            skewX: 20,
            x: 0,
            force3D: true,
            ease: 'Power3.easeInOut',
        }),
    ];

    logoDisappear = () => [
        TweenLite.to(this.references.animatedLogoCravath, 0.85, {
            skewX: 20,
            x: 226,
            force3D: true,
            ease: 'Power4.easeInOut',
        }),
        TweenLite.to(this.references.animatedLogoSlash, 1, {
            delay: 0.45,
            scaleY: 0,
            skewX: -20,
            force3D: true,
            ease: 'Power4.easeInOut',
        }),
        TweenLite.to(this.references.animatedLogo200, 0.85, {
            skewX: 20,
            x: -123,
            force3D: true,
            ease: 'Power4.easeInOut',
            onComplete: this.references.layout.headerReveal,
        }),
    ];

    hideLogo = () => {
        if (this.refsExist()) {
            this.references.animatedLogo.classList.add('animated-logo--complete');
        }
    };

    setRef = (identifier, element) => {
        this.references[`${identifier}`] = element;
    };

    render() {
        return (
            <Layout
                location={this.props.location}
                bodyClasses="background-darkBlue"
                isHomepage
                ref={this.setRef.bind(this, 'layout')}
            >
                <div className="homepage-video-container">
                    {eras.map((era, idx) => {
                        const asset = {
                            webm: `${era.video}.webm`,
                            mp4: `${era.video}.mp4`,
                            key: `video-${idx}`,
                            loop: true,
                            autoPlay: false,
                        };
                        return (
                            <AnimatedMedia
                                key={asset.key}
                                asset={asset}
                                className={`background-video background-video--homepage js-background-video js-background-video--${idx}`}
                                videoPosition={era.videoPosition}
                            />
                        );
                    })}
                </div>
                <div className="homepage__background-container">
                    <Picture asset={backgroundImage} className="homepage__background" />
                    <HomepageBackgroundGradient />
                </div>
                <section className="nav-link-container l-max-content-width">
                    <div className="animated-logo-container">
                        <div className="animated-logo" ref={this.setRef.bind(this, 'animatedLogo')}>
                            <div className="animated-logo__left">
                                <div
                                    className="animated-logo__item"
                                    ref={this.setRef.bind(this, 'animatedLogoCravath')}
                                >
                                    <img src={animatedLogoCravath} alt="Cravath" />
                                </div>
                            </div>
                            <div className="animated-logo__middle" ref={this.setRef.bind(this, 'animatedLogoSlash')}>
                                <div className="animated-logo__item">
                                    <img src={animatedLogoSlash} alt="/" />
                                </div>
                            </div>
                            <div className="animated-logo__right">
                                <div className="animated-logo__item" ref={this.setRef.bind(this, 'animatedLogo200')}>
                                    <img src={animatedLogo200} alt="200" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="nav-link__content">
                        <div
                            className="nav-link__years nav-link__years--homepage yellow"
                            ref={this.setRef.bind(this, 'navLinkYears')}
                        >
                            <p className="type-homepage-hero nav-link__year nav-link__year--active js-nav-link__year--default">
                                The First <br className="u-mobile-only" /> 200 Years
                            </p>
                            {eras.map((era, idx) => {
                                return (
                                    <p
                                        className={`nav-link__year js-nav-link__year-${idx} type-homepage-years`}
                                        key={`nav-link__year-${idx}`}
                                        dangerouslySetInnerHTML={{ __html: era.years }}
                                    />
                                );
                            })}
                        </div>
                        <p className="white type-p homepage-subhero" ref={this.setRef.bind(this, 'subhero')}>
                            In 2019, we celebrated our bicentennial.&nbsp;
                            <br className="u-mobile-only" />
                            Our history mirrors that of our nation.
                        </p>
                        <div className="white">
                            <div className="nav-link-eras-container">
                                <nav className="nav-link-eras js-nav-link-eras type-era-links ">
                                    <EraLinks
                                        eras={eras}
                                        pauseVideo={pauseVideo}
                                        playVideo={playVideo}
                                        ref={this.setRef.bind(this, 'navLinkEras')}
                                    />
                                </nav>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}

export default HomePage;

import { query } from './utils';
if (typeof window !== 'undefined') {
    require('intersection-observer');
}

const moduleAlreadyObservedClass = 'module--observed';

const options = {
    rootMargin: '0px 0px 10px 0px',
    threshold: 0,
};

export default function initComponentReveal() {
    const currentScrollPosition = window.pageYOffset;
    const observer = new IntersectionObserver((els) => {
        els.forEach((el) => {
            if (el.isIntersecting) {
                el.target.classList.add(moduleAlreadyObservedClass);
                observer.unobserve(el.target);
            }
        });
    }, options);
    const modules = query('.module');
    modules.forEach((module) => {
        observer.observe(module);
        if (module.offsetTop < currentScrollPosition) {
            module.classList.add(moduleAlreadyObservedClass);
            observer.unobserve(module);
        }
    });
}

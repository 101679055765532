import { querySingle, isIEorEdge } from './utils';

const transitionDelay = 300;

const yearActiveClass = 'nav-link__year--active';
const erasActiveClass = 'nav-link-eras--active';

if (typeof Element !== 'undefined' && !Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector;
}

export function pauseVideo(index) {
    const video = querySingle(`.js-background-video--${index}`);
    const yearDisplay = querySingle(`.js-nav-link__year-${index}`);
    const defaultYearDisplay = querySingle('.js-nav-link__year--default');
    const eras = querySingle('.js-nav-link-eras');
    const eraLink = querySingle(`.js-nav-link-${index}`);
    video.classList.remove('background-video--active');
    // TODO - see if can be done with onAnimationEnd/onTransitionEnd instead of setTimeout
    setTimeout(() => {
        if (!eraLink.matches(':hover')) {
            try {
                video.pause();
                video.currentTime = 0;
                if (isIEorEdge()) {
                    const parentNode = video.parentNode;
                    if (parentNode.tagName === 'OBJECT-FIT') {
                        parentNode.style.opacity = 0;
                    }
                }
            } catch (err) {}
        }
    }, transitionDelay);
    yearDisplay.classList.remove(yearActiveClass);
    defaultYearDisplay.classList.add(yearActiveClass);
    eras.classList.remove(erasActiveClass);
}

export function playVideo(index) {
    const video = querySingle(`.js-background-video--${index}`);
    const yearDisplay = querySingle(`.js-nav-link__year-${index}`);
    const defaultYearDisplay = querySingle('.js-nav-link__year--default');
    const eras = querySingle('.js-nav-link-eras');
    if (!video.classList.contains('background-video--active') && window.innerWidth > 768) {
        try {
            video.classList.add('background-video--active');
            video.play();
            if (isIEorEdge()) {
                const parentNode = video.parentNode;
                if (parentNode.tagName === 'OBJECT-FIT') {
                    parentNode.style.opacity = 1;
                }
            }
            defaultYearDisplay.classList.remove(yearActiveClass);
            yearDisplay.classList.add(yearActiveClass);
            eras.classList.add(erasActiveClass);
        } catch (err) {}
    }
}

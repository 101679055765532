import React from 'react';

const UpperEyebrow = ({ year }) => {
    if (year) {
        return <p className="type-eyebrow module__eyebrow lightBlue u-text-left">{year}</p>;
    }
    return '';
};

export default UpperEyebrow;

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { TimelineLite } from 'gsap';

import '../styles/nav-link.scss';
import InternalLink from './internal-link';

const UrlSafeString = require('url-safe-string');
const tagGenerator = new UrlSafeString();

class EraLinks extends React.Component {
    constructor(props) {
        super(props);
        this.eras = props.eras;
        this.links = [];
        this.timeline = new TimelineLite({ paused: true });
    }

    componentDidMount() {
        const { noIntro } = this.props;
        if (noIntro) {
            this.timeline.progress(1, false);
        } else {
            this.timeline.staggerTo(
                this.links,
                0.5,
                {
                    y: 0,
                    autoAlpha: 1,
                    visibility: 'visible',
                    ease: 'Power1.easeOut',
                },
                0.5,
            );
        }
    }

    revealEraLinks() {
        this.timeline.play();
    }

    render() {
        const { noIntro, page } = this.props;
        const noIntroClass = noIntro ? 'nav-link--no-intro' : '';

        return (
            <ul className="nav white">
                {this.eras.map((era, idx) => {
                    const inactive = era.page === page ? 'nav-link--inactive' : '';
                    const eraLinkDisplay = (
                        <>
                            {era.page} <span className="icon-caret-right nav-link-eras__icon" />
                        </>
                    );
                    return (
                        <li
                            className={`nav-link ${inactive} u-hover-underline u-hover-underline--wide u-hover-icon-wiggle js-nav-link-${idx} ${noIntroClass}`}
                            key={`link-${idx}`}
                            onMouseEnter={() => this.props.playVideo(idx)}
                            onMouseLeave={() => this.props.pauseVideo(idx)}
                            ref={(li) => (this.links[idx] = li)}
                        >
                            {era.page === page ? (
                                <span className="era-link-display">{eraLinkDisplay}</span>
                            ) : (
                                <InternalLink className="era-link-display" to={tagGenerator.generate(era.page || '#')}>
                                    {eraLinkDisplay}
                                </InternalLink>
                            )}
                        </li>
                    );
                })}
            </ul>
        );
    }
}

EraLinks.propTypes = {
    noIntro: PropTypes.bool,
    page: PropTypes.string,
    eras: PropTypes.array,
    pauseVideo: PropTypes.func,
    playVideo: PropTypes.func,
};

EraLinks.defaultProps = {
    noIntro: false,
    page: '',
    eras: [],
    pauseVideo: () => {},
    playVideo: () => {},
};

export default EraLinks;

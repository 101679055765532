import React from 'react';

const ModuleHeading = ({ headingClasses, headingText }) => {
    if (headingText) {
        return <h3 className={`${headingClasses}`} dangerouslySetInnerHTML={{ __html: headingText }} />;
    }
    return '';
};

export default ModuleHeading;

import * as React from 'react';

import '../styles/mobile-overlay.scss';
import OverlayContent from './overlay-content';

const mobileOverlayActiveClass = 'mobile-overlay--active';

const MobileOverlay = ({ active, hideOverlay }) => {
    const classes = ['mobile-overlay', 'js-mobile-overlay', 'yellow'];
    if (active) {
        classes.push(mobileOverlayActiveClass);
    }
    return (
        <section className={classes.join(' ')}>
            <header className="mobile-overlay__header">
                <span className="icon-x js-icon-x mobile-overlay__icon" onClick={hideOverlay} />
            </header>
            <div className="overlay-content-wrapper">
                <OverlayContent />
            </div>
        </section>
    );
};

export default MobileOverlay;

import * as React from 'react';
import { withPrefix, Link } from 'gatsby';
import { isIE } from '../utils/utils';

const InternalLink = ({ to, children, className }) => {
    const linkAttributes = {
        href: withPrefix(`/${to}`),
        className,
    };

    return isIE() ? (
        <a {...linkAttributes}>{children}</a>
    ) : (
        <Link to={to} className={className}>
            {children}
        </Link>
    );
};

export default InternalLink;

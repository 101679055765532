import * as React from 'react';
import InternalLink from './internal-link';

import '../styles/overlay.scss';
import ExternalLink from './external-link';
import { MAIN_CRAVATH_SITE, ABOUT_PAGE_TITLE } from '../utils/constants';

import eras from '../data/eras.json';
const UrlSafeString = require('url-safe-string');
const tagGenerator = new UrlSafeString();

const OverlayContent = () => {
    return (
        <div className="overlay-content type-overlay-links">
            <div className="overlay-item">
                <InternalLink to="/about" className="u-uppercase yellow u-hover-underline">
                    {ABOUT_PAGE_TITLE}
                </InternalLink>
            </div>
            <div className="overlay-item">
                <ExternalLink className="u-uppercase u-hover-underline" to={MAIN_CRAVATH_SITE} newTab={true}>
                    Cravath.com
                </ExternalLink>
            </div>
            <hr className="overlay-divider overlay-item" />
            <h3 className="type-overlay-links white u-uppercase overlay-item">Our History:</h3>
            <ol className="nav overlay-nav yellow overlay-item">
                {eras.map((era, idx) => {
                    return (
                        <li className={`nav-link nav-link--no-intro overlay-item u-uppercase`} key={`link-${idx}`}>
                            <InternalLink
                                className="u-hover-underline u-hover-underline--wide"
                                to={tagGenerator.generate(era.page || '#')}
                            >
                                {era.page}
                            </InternalLink>
                        </li>
                    );
                })}
            </ol>
        </div>
    );
};

export default OverlayContent;

import * as React from 'react';
import * as PropTypes from 'prop-types';
import { debounce } from 'throttle-debounce';

import AnimatedMedia from '../components/animated-media';

import '../styles/era-header.scss';
import InternalLink from './internal-link';

import {
    DESKTOP,
    MOBILE,
    DESKTOP_BREAKPOINT,
    TABLET_SHORT,
    TABLET_SHORT_HEIGHT,
    STANDARD_TRANSITION_TIME,
} from '../utils/constants';

const UrlSafeString = require('url-safe-string');
const tagGenerator = new UrlSafeString();

const expandedEraHeight = 340;

const condensedEraHeight = {};
condensedEraHeight[MOBILE] = 60;
condensedEraHeight[TABLET_SHORT] = 60;
condensedEraHeight[DESKTOP] = 100;

class EraHeader extends React.Component {
    constructor(props) {
        super(props);
        this.textOnlyHeader = null;
        this.isCancelled = false;

        this.state = {
            scrollTop: 0,
            screenSize: MOBILE,
            introModuleBottomOffset: 0, // TODO: Needs to be a ref to the height of the intro module
            condensedHeaderClasses: ['era-header-text-only', 'background-mediumBlue', 'era-header--transform'],
        };
    }

    componentDidMount = () => {
        if (this.textOnlyHeader) {
            window.addEventListener('scroll', this.bindScroll);
            window.addEventListener('resize', this.bindResize);
            const scrollTop = window.pageYOffset;
            const screenSize = this.getCurrentScreenSize();
            this.setState({ scrollTop, screenSize });
            const newEraClasses = [...this.state.condensedHeaderClasses, 'era-header-text-only--ready'];
            setTimeout(() => {
                if (!this.isCancelled) {
                    this.setState({ condensedHeaderClasses: newEraClasses });
                }
            }, STANDARD_TRANSITION_TIME);
        }
    };

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.bindScroll);
        window.removeEventListener('resize', this.bindResize);
        this.isCancelled = true;
    };

    getCurrentScreenSize = () => {
        let screenSizeDescriptor = MOBILE;
        if (window.innerWidth > DESKTOP_BREAKPOINT) {
            screenSizeDescriptor = window.innerHeight <= TABLET_SHORT_HEIGHT ? TABLET_SHORT : DESKTOP;
        }
        return screenSizeDescriptor;
    };

    setRef = (element) => {
        this.textOnlyHeader = element;
    };

    bindResize = debounce(15, () => {
        this.setState({ screenSize: this.getCurrentScreenSize() });
    });

    bindScroll = debounce(15, () => {
        const scrollTop = window.pageYOffset;
        this.setState({ scrollTop });
    });

    getCondensedEraHeaderTranslationPercentage = () => {
        const currentCondensedEraHeight = condensedEraHeight[this.state.screenSize];
        const eraHeightDifference = expandedEraHeight - currentCondensedEraHeight;
        let scrollPositionPastIntroModule = this.state.scrollTop - this.state.introModuleBottomOffset;
        if (scrollPositionPastIntroModule < 0) {
            scrollPositionPastIntroModule = 0;
        } else if (scrollPositionPastIntroModule >= eraHeightDifference) {
            scrollPositionPastIntroModule = eraHeightDifference;
        }
        return 100 - Math.floor((scrollPositionPastIntroModule / eraHeightDifference) * 100);
    };

    render() {
        const { page, nextPage, previousPage, video, videoPosition, years, videoRef } = this.props;
        const previousArrowClasses = ['icon', 'icon-caret-left', 'era-header__icon', 'era-header__icon-left'];
        const nextArrowClasses = ['icon', 'icon-caret-right', 'era-header__icon', 'era-header__icon-right'];
        const asset = {
            webm: `${video}.webm`,
            mp4: `${video}.mp4`,
            loop: true,
            autoPlay: true,
        };

        if (!previousPage) {
            previousArrowClasses.push('u-hidden');
        }
        if (!nextPage) {
            nextArrowClasses.push('u-hidden');
        }

        const condensedPreviousArrowClasses = previousArrowClasses.concat('era-header__icon--condensed');
        const condensedNextArrowClasses = nextArrowClasses.concat('era-header__icon--condensed');

        return (
            <div className="background-darkBlue u-relative">
                <div className="era-header era-header--pinned background-darkBlue">
                    <div className="era-header-nav">
                        <InternalLink
                            to={tagGenerator.generate(previousPage || '#')}
                            className={previousArrowClasses.join(' ')}
                        />
                        <div className="era-header-title-wrapper">
                            <h1 className="l-max-content-width era-header-title type-page-title">{page}</h1>
                            <p
                                className="type-p yellow u-text-center era-header__years"
                                dangerouslySetInnerHTML={{ __html: years }}
                            />
                        </div>
                        <InternalLink
                            to={tagGenerator.generate(nextPage || '#')}
                            className={nextArrowClasses.join(' ')}
                        />
                    </div>
                    <AnimatedMedia
                        asset={asset}
                        className={`background-video background-video--active`}
                        videoPosition={videoPosition}
                        ref={videoRef}
                    />
                </div>
                <div
                    className={this.state.condensedHeaderClasses.join(' ')}
                    ref={this.setRef}
                    style={{ transform: `translateY(-${this.getCondensedEraHeaderTranslationPercentage()}%)` }}
                >
                    <div className="era-header-nav">
                        <InternalLink
                            to={tagGenerator.generate(previousPage || '#')}
                            className={condensedPreviousArrowClasses.join(' ')}
                        />
                        <div>
                            <h1 className="l-max-content-width era-header-title type-page-title type-page-title--condensed">
                                {page}
                            </h1>
                        </div>
                        <InternalLink
                            to={tagGenerator.generate(nextPage || '#')}
                            className={condensedNextArrowClasses.join(' ')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

EraHeader.propTypes = {
    page: PropTypes.string,
    previousPage: PropTypes.string,
    nextPage: PropTypes.string,
};

EraHeader.defaultProps = {
    page: '',
    previousPage: '',
    nextPage: '',
};

export default EraHeader;

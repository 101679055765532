import React from 'react';
import PropTypes from 'prop-types';

class Credits extends React.Component {
    componentDidMount() {
        this.showCreditsInConsole();
    }

    showCreditsInConsole() {
        const { designer, developer, cms } = this.props;

        console.info(designer);
        console.info(developer);
        console.info(cms);
    }

    render() {
        const { designer, developer, cms } = this.props;

        const attributionComment = `
            // Designed by ${designer}
            // Developed by ${developer}
            // Powered by ${cms}
        `;

        return (
            <script
                dangerouslySetInnerHTML={{
                    __html: attributionComment,
                }}
            />
        );
    }
}

Credits.propTypes = {
    designer: PropTypes.string,
    developer: PropTypes.string,
    cms: PropTypes.string,
};

Credits.defaultProps = {
    developer: 'RubensteinTech - https://www.rubensteintech.com/',
    cms: 'RubyApps - https://www.rubyapps.com/',
};

export default Credits;

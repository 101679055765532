import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, withPrefix } from 'gatsby';

import Header from './header';

import '../styles/main.scss';

import Footer from './footer';
import Credits from './credits';
import { isIE } from '../utils/utils';

import {
    SITE_KEYWORDS,
    SITE_DESCRIPTION,
    OPEN_GRAPH_IMAGE,
    TWITTER_USERNAME,
    GOOGLE_SITE_VERIFICATION,
} from '../utils/constants';

export const PureLayout = ({ data, children, bodyClasses, isHomepage, state, headerRef, location, pageTitle }) => {
    const domain = location && location.origin ? location.origin : 'https://200.cravath.com';
    let title = pageTitle ? `${pageTitle} | ${data.site.siteMetadata.title}` : data.site.siteMetadata.title;
    return (
        <>
            <Helmet
                title={title}
                meta={[
                    { name: 'description', content: SITE_DESCRIPTION },
                    { name: 'google-site-verification', content: GOOGLE_SITE_VERIFICATION },
                    { name: 'keywords', content: SITE_KEYWORDS },
                    { property: 'og:description', content: SITE_DESCRIPTION },
                    { property: 'og:image', content: `${domain}${OPEN_GRAPH_IMAGE}` },
                    { property: 'og:title', content: title },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:url', content: data.site.siteMetadata.siteUrl },
                    { property: 'twitter:card', content: 'summary' },
                    { property: 'twitter:description', content: SITE_DESCRIPTION },
                    { property: 'twitter:site', content: TWITTER_USERNAME },
                    { property: 'twitter:title', content: title },
                ]}
                bodyAttributes={{
                    class: bodyClasses || 'background-white',
                }}
            >
                <html lang="en" />
            </Helmet>
            <Credits designer="Carbone Smolan Agency - https://www.carbonesmolan.com" />
            <div className={state.deFactoBodyClasses.join(' ')}>
                <Header isHomepage={isHomepage} ref={headerRef} />
                <main className="de-facto-body__content">{children}</main>
                <Footer />
            </div>
        </>
    );
};

class Layout extends React.Component {
    constructor(props) {
        super(props);

        this.header = null;
        const deFactoBodyClasses = ['de-facto-body', 'type-p'];
        if (props.isHomepage) {
            deFactoBodyClasses.push('page__homepage');
        }
        this.state = {
            deFactoBodyClasses,
        };
    }

    componentDidMount() {
        const { deFactoBodyClasses } = this.state;
        const updatedClasses = [...deFactoBodyClasses];

        if (!('ontouchstart' in document.documentElement)) {
            updatedClasses.push('no-touch');
        }
        if (isIE()) {
            updatedClasses.push('ie');
        }
        if (deFactoBodyClasses.length !== updatedClasses.length) {
            this.setState({ deFactoBodyClasses: updatedClasses });
        }
    }

    headerReveal = () => {
        if (this.header) {
            this.header.reveal();
        }
    };

    setRef = (element) => {
        this.header = element;
    };

    render() {
        return (
            <StaticQuery
                query={graphql`
                    query SiteTitleQuery {
                        site {
                            siteMetadata {
                                title
                                siteUrl
                            }
                        }
                    }
                `}
                render={(data) => <PureLayout {...this.props} data={data} state={this.state} headerRef={this.setRef} />}
            />
        );
    }
}
Layout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClasses: PropTypes.string,
    isHomepage: PropTypes.bool,
};

Layout.defaultProps = {
    children: '',
    bodyClasses: '',
    isHomepage: false,
};

export default Layout;

import React from 'react';

import ExternalLink from './external-link';

import '../styles/prefooter.scss';
import { MAIN_CRAVATH_SITE } from '../utils/constants';

const PreFooter = () => (
    <section className="background-yellow darkBlue prefooter">
        <div className="l-max-content-width prefooter__content">
            <ExternalLink
                to={MAIN_CRAVATH_SITE}
                newTab
                className="darkBlue u-uppercase u-hover-icon-wiggle type-callout prefooter__link"
            >
                See Cravath Today <span className="icon icon-arrow prefooter__icon" />
            </ExternalLink>
        </div>
    </section>
);

export default PreFooter;

import * as React from 'react';
import Masonry from 'react-masonry-component';

import Layout from '../components/layout';
import {
    BioModule,
    ColumnSpacerModule,
    IntroModule,
    PortraitModule,
    SpacerModule,
    StandardAssetModule,
    StandardTextModule,
    WideAssetModule,
    WideTextModule,
    YearModule,
} from '../components/modules';
import initComponentReveal from '../utils/componentReveal';
import { isIEorEdge, isIE } from '../utils/utils';
import EraHeader from '../components/era-header';
import AnimatedMedia from '../components/animated-media';
import { Picture } from '../components/picture';
import '../styles/modules.scss';
import EraFooter from '../components/era-footer';

const moduleMap = {
    bio: BioModule,
    columnSpacer: ColumnSpacerModule,
    intro: IntroModule,
    portrait: PortraitModule,
    spacer: SpacerModule,
    standardAsset: StandardAssetModule,
    standardText: StandardTextModule,
    wideAsset: WideAssetModule,
    wideText: WideTextModule,
    year: YearModule,
};

const masonryOptions = {
    columnWidth: '.grid-sizer',
    gutter: '.gutter-sizer',
    horizontalOrder: true,
    itemSelector: '.module',
    percentPosition: true,
    transitionDuration: 0,
};

class Era extends React.Component {
    constructor(props) {
        super(props);
        this.modules = props.pageContext.modules;
        this.page = props.pageContext.page;
        this.previousPage = props.pageContext.previousPage;
        this.nextPage = props.pageContext.nextPage;
        this.video = props.pageContext.video;
        this.videoPosition = props.pageContext.videoPosition;
        this.years = props.pageContext.years;
        this.videoRef = null;
    }

    componentDidMount() {
        if (isIE()) {
            window.addEventListener('beforeunload', () => {
                window.scrollTo(0);
            });
        }
        if (!this.componentRevealInitialized) {
            this.componentRevealInitialized = true;
            initComponentReveal();
        }
        if (isIEorEdge() && this.videoRef && this.videoRef.current) {
            this.videoRef.current.play();
        }
    }

    retriggerLayoutAfterVideoDataLoad = () => {
        this.masonry.layout();
    };

    setMasonryRef = (element) => {
        this.masonry = this.masonry || element.masonry;
    };

    setVideoRef = (element) => {
        if (element) {
            this.videoRef = element.videoRef;
        }
    };

    render() {
        return (
            <Layout location={this.props.location} pageTitle={this.page}>
                <EraHeader
                    page={this.page}
                    previousPage={this.previousPage}
                    nextPage={this.nextPage}
                    video={this.video}
                    videoPosition={this.videoPosition}
                    years={this.years}
                    videoRef={this.setVideoRef}
                />
                <div className="l-max-content-width l-margin-bottom-150px darkGray">
                    <Masonry options={masonryOptions} ref={this.setMasonryRef}>
                        <div className="grid-sizer" />
                        <div className="gutter-sizer" />
                        {this.modules.map((module, idx) => {
                            const headingClasses = [
                                `type-${module.headingSize}`,
                                `${module.headingColor || 'lightBlue'}`,
                                'module__heading',
                            ].join(' ');
                            module.headingClasses = headingClasses;
                            const moduleClasses = [module.moduleClasses].join(' ');
                            module.moduleClasses = moduleClasses;
                            module.key = `module-${idx}`;
                            if (!moduleMap[module.type]) {
                                console.debug(module);
                                throw new Error(
                                    `Error: Module type "${
                                        module.type
                                    }" was specified but doesn't exist Check the console debug output to see more details about the problematic declaration`,
                                );
                            }
                            const Module = moduleMap[module.type];
                            if (module.asset) {
                                const MediaComponent = module.asset.animated ? AnimatedMedia : Picture;
                                // TODO - see if can be done without spreading `module` into both Module and MediaComponent
                                return (
                                    <Module {...module}>
                                        <MediaComponent
                                            {...module}
                                            className="module__asset"
                                            videoLoadedHandler={this.retriggerLayoutAfterVideoDataLoad}
                                        />
                                    </Module>
                                );
                            }
                            return <Module {...module} />;
                        })}
                    </Masonry>
                </div>
                <EraFooter page={this.page} />
            </Layout>
        );
    }
}

export default Era;

import * as React from 'react';
import * as PropTypes from 'prop-types';
import * as objectFitVideos from 'object-fit-videos';

import { conditionallyRenderSource, isIEorEdge } from '../utils/utils';

import '../styles/animated-media.scss';

class AnimatedMedia extends React.Component {
    constructor(props) {
        super(props);
        this.videoPosition = this.props.videoPosition;
        this.videoLoadedHandler = this.props.videoLoadedHandler || null;
        const classes = props.className.split(' ');
        if (this.videoPosition) {
            classes.push(`u-object-position-${this.videoPosition}`);
            classes.push('u-hidden');
        }
        this.videoRef = React.createRef();
        this.state = {
            classes,
        };
    }

    componentDidMount() {
        const tempClasses = [...this.state.classes];
        if (this.videoPosition && isIEorEdge()) {
            objectFitVideos(this.videoRef.current, () => {
                if (tempClasses.indexOf('u-hidden') >= 0) {
                    tempClasses.splice(tempClasses.indexOf('u-hidden'), 1);
                }
                this.setState({
                    classes: tempClasses,
                });
                this.videoRef.current.parentNode.style.visibility = 'visible';
            });
        }
        if (tempClasses.indexOf('u-hidden') >= 0) {
            tempClasses.splice(tempClasses.indexOf('u-hidden'), 1);
        }
        this.setState({
            classes: tempClasses,
        });
    }

    render() {
        const videoAttributes = {
            playsInline: true,
            muted: true,
            autoPlay: true,
        };
        const { webm, mp4, loop, autoPlay } = this.props.asset;

        if (loop) {
            videoAttributes.loop = true;
        }

        if (autoPlay === false) {
            videoAttributes.autoPlay = false;
        }

        if (!(webm || mp4)) {
            return null;
        }

        return (
            <video
                {...videoAttributes}
                className={this.state.classes.join(' ')}
                ref={this.videoRef}
                onLoadedData={this.videoLoadedHandler}
            >
                {conditionallyRenderSource(webm, 'webm')}
                {conditionallyRenderSource(mp4, 'mp4')}
                Your browser doesn't support HTML5 video
            </video>
        );
    }
}

AnimatedMedia.propTypes = {
    className: PropTypes.string,
    asset: PropTypes.shape({
        webm: PropTypes.string,
        mp4: PropTypes.string,
        loop: PropTypes.bool,
        autoPlay: PropTypes.bool,
    }),
};

AnimatedMedia.defaultProps = {
    asset: {
        loop: false,
        autoPlay: true,
    },
};

export default AnimatedMedia;

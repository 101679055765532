import React from 'react';
import Layout from '../components/layout';

const NotFoundPage = (props) => (
    <Layout location={props.location}>
        <section className="l-max-content-width l-margin-top-10 darkGray u-text-center">
            <h1 className="type-h1 mediumBlue">404: Page Not Found</h1>
        </section>
    </Layout>
);

export default NotFoundPage;

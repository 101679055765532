import * as React from 'react';
import * as PropTypes from 'prop-types';

import EraLinks from '../components/era-links';
import AnimatedMedia from '../components/animated-media';
import { playVideo, pauseVideo } from '../utils/media';

import '../styles/era-footer.scss';

import eras from '../data/eras.json';

const EraFooter = ({ page }) => {
    return (
        <div className="background-darkBlue era-footer-container">
            {eras.map((era, idx) => {
                const asset = {
                    webm: `${era.video}.webm`,
                    mp4: `${era.video}.mp4`,
                    key: `video-${idx}`,
                    loop: true,
                    autoPlay: false,
                };
                const videoPosition = era.videoPosition || 'center';
                return (
                    <AnimatedMedia
                        key={asset.key}
                        asset={asset}
                        className={`background-video js-background-video js-background-video--${idx}`}
                        videoPosition={videoPosition}
                    />
                );
            })}
            <div className="l-max-content-width era-footer">
                <div className="nav-link__content">
                    <div className="nav-link__years yellow type-h2">
                        <p className="nav-link__year nav-link__year--active js-nav-link__year--default">
                            The First <br className="u-mobile-only" /> 200 Years
                        </p>
                        {eras.map((era, idx) => {
                            return (
                                <p
                                    className={`nav-link__year js-nav-link__year-${idx} type-h1`}
                                    key={`nav-link__year-${idx}`}
                                    dangerouslySetInnerHTML={{ __html: era.years }}
                                />
                            );
                        })}
                    </div>
                    <div className="nav-link-eras-container white">
                        <nav className="nav-link-eras js-nav-link-eras type-era-links">
                            <EraLinks page={page} eras={eras} pauseVideo={pauseVideo} playVideo={playVideo} noIntro />
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

EraFooter.propTypes = {
    page: PropTypes.string,
};

EraFooter.defaultProps = {
    page: '',
};

export default EraFooter;

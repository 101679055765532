import React from 'react';

import Layout from '../components/layout';
import AnimatedMedia from '../components/animated-media';

import '../styles/about.scss';
import InternalLink from '../components/internal-link';
import { ABOUT_PAGE_TITLE } from '../utils/constants';

const asset = {
    webm: 'ERAGATE1_HOMEPAGE_765PX.webm',
    mp4: 'ERAGATE1_HOMEPAGE_765PX.mp4',
    loop: true,
    autoPlay: true,
};

const AboutPage = (props) => (
    <Layout location={props.location} bodyClasses="background-darkBlue white" pageTitle={ABOUT_PAGE_TITLE}>
        <section className="about-hero">
            <h1 className="type-page-title yellow u-text-center about-hero__title">
                Celebrating 200 Years <br /> of Partnership
            </h1>
            <p className="about-content__paragraph">
                <b className="about-content__first-line">In 2019, we celebrated our bicentennial.&#32; </b>
                We have called New York City home since Richard Blatchford established an office at 140 Water Street on
                a spring day in 1819. Four years later, his lifelong colleague and friend, William Seward, began a
                partnership with Elijah Miller in Auburn. In 1854, the offices combined and in 1899, another visionary
                leader, Paul Cravath, joined the Firm. He developed the model for talent development and client service
                that would become the foundation for the modern American law firm and the most influential investment
                banking and consulting partnerships. To this day, we proudly display the name of every single partner
                who has been a part of our Firm since that beginning 200 years ago.
            </p>
            <p className="about-content__paragraph">
                <b className="about-content__first-line">Our history mirrors that of our nation.&#32; </b>
                We have played a role in each chapter, through our work with the inventors, financiers and institutions
                that shaped the business and financial landscape, and our involvement in the issues confronting society
                as values and beliefs were tested. We recount our story through the work we have done and the deeds of
                remarkable men and women who rose to make a difference to their time.
            </p>
            <p className="about-content__paragraph">
                <b className="about-content__first-line"> Integral to our story is our culture.&#32; </b>
                One in which each individual is challenged to strive for excellence, equipped with rigorous and
                expansive training and supported by a model designed to spur collaboration. The investment we make in
                our people, the values we seek to imprint on each generation and the pioneering spirit we bring to each
                endeavor, define who we are and what we always want Cravath to represent.
            </p>
        </section>
        <section className="about-page__footer">
            <AnimatedMedia asset={asset} className="about__asset" />
            <InternalLink
                to="/industrialization-civil-war"
                className="type-page-title u-hover-icon-wiggle yellow u-text-center about-page__footer-link"
            >
                Explore Our Story
                <span className="icon icon-caret-right about-page__footer-icon" />
            </InternalLink>
        </section>
    </Layout>
);

export default AboutPage;

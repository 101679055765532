import * as React from 'react';
import { conditionallyRenderSource } from '../utils/utils';
import { withPrefix } from 'gatsby';

export class Picture extends React.Component {
    render() {
        const { asset, className } = this.props;
        const fallbackImage = asset.jpg ? asset.jpg : asset.png;
        const { mediaQueries = [] } = asset;
        return (
            <picture>
                {mediaQueries.map((asset, idx) =>
                    conditionallyRenderSource(asset.src, asset.type, asset.media, `picture-${idx}`),
                )}
                {conditionallyRenderSource(asset.webp, 'webp')}
                <img className={className} src={withPrefix(`/images/${fallbackImage}`)} alt="" />
            </picture>
        );
    }
}

import React from 'react';

const ModuleBody = ({ bodyCopy, classes }) => {
    if (bodyCopy) {
        return <p className={classes} dangerouslySetInnerHTML={{ __html: bodyCopy }} />;
    }
    return '';
};

export default ModuleBody;

import React from 'react';
import { withPrefix } from 'gatsby';

/**
 * conditionallyRenderSource
 *
 * @version	v1.0.0	Wednesday, December 12th, 2018.
 * @access	export
 * @global
 * @param	{String}	asset
 * @param	{String}	type
 * @param	{String}	[mediaQuery]
 * @return	mixed
 */
export function conditionallyRenderSource(asset, type, mediaQuery, key) {
    const media = mediaQuery ? `(${mediaQuery})` : null;
    if (asset) {
        switch (type) {
            case 'webp':
            case 'png':
                return (
                    <source key={key} srcSet={withPrefix(`/images/${asset}`)} type={`image/${type}`} media={media} />
                );
            case 'jpg':
                return <source key={key} srcSet={withPrefix(`/images/${asset}`)} type={`image/jpeg`} media={media} />;
            case 'webm':
            case 'mp4':
                return <source key={key} src={withPrefix(`/videos/${asset}`)} type={`video/${type}`} />;
            default:
                break;
        }
    }
    return null;
}

/*
 * query
 * Abstraction to querySelectorAll for increased
 * performance and greater usability
 * @param {String} selector
 * @param {Element} [context]
 * @return {Array}
 */

export function query(selector, context) {
    const simpleRe = /^(#?[\w-]+|\.[\w-.]+)$/;
    const periodRe = /\./g;
    const slice = [].slice;
    context = context || document;
    // Redirect call to the more performant function
    // if it's a simple selector and return an array
    // for easier usage
    if (simpleRe.test(selector)) {
        switch (selector[0]) {
            case '#':
                return [document.getElementById(selector.substr(1))];
            case '.':
                return slice.call(context.getElementsByClassName(selector.substr(1).replace(periodRe, ' ')));
            default:
                return slice.call(context.getElementsByTagName(selector));
        }
    }
    // If not a simple selector, query the DOM as usual
    // and return an array for easier usage
    return slice.call(context.querySelectorAll(selector));
}

/**
 * querySingle
 *
 * @version	v1.0.0	Wednesday, December 12th, 2018.
 * @access	export
 * @global
 * @param {String} selector
 * @param {Element} [context]
 * @return {Element}
 */
export function querySingle(selector, context) {
    return query(selector, context)[0];
}

function isIE10() {
    if (typeof navigator !== 'undefined') {
        return /MSIE 10/i.test(navigator.userAgent);
    }
    return false;
}

function isIE11() {
    if (typeof navigator !== 'undefined') {
        return navigator.userAgent.match(/Trident/) && navigator.userAgent.match(/rv:11/);
    }

    return false;
}

export function isIE() {
    return isIE10() || isIE11();
}

export function isEdge() {
    return window.navigator.userAgent.indexOf('Edge') > -1;
}

export function isIEorEdge() {
    return isIE() || isEdge();
}

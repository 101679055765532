export const DESKTOP = 'desktop';
export const MOBILE = 'mobile';
export const TABLET_SHORT = 'tabletShort';

export const DESKTOP_BREAKPOINT = 768;
export const TABLET_SHORT_HEIGHT = 750;

export const STANDARD_TRANSITION_TIME = 300; // Matches SCSS standard-transition

export const MAIN_CRAVATH_SITE = 'https://www.cravath.com';
export const SITE_DESCRIPTION =
    "In 2019, we celebrate Cravath's bicentennial. Our history mirrors that of our nation. Integral to our story is our culture.";
export const SITE_KEYWORDS =
    'Cravath bicentennial; Celebrating 200 years of partnership; Cravath 200; law firm bicentennial; law firm anniversary; Cravath anniversary; Faiza Saeed; Evan Chesler; Paul Cravath; William Seward; Richard Blatchford; Robert Swaine; Hoyt Moore; model for talent development; modern American law firm ';
export const OPEN_GRAPH_IMAGE = '/images/2430_CravathSocialMedia_OpenGraph_v01_190102.jpg';
export const TWITTER_USERNAME = '@Cravath';
export const GOOGLE_SITE_VERIFICATION = 'gIEDI92dQpIfl-annTbieXzcXH4I5ncUn-wg8A2m3B4';

export const ABOUT_PAGE_TITLE = 'About The Bicentennial';

import React from 'react';

import PreFooter from './pre-footer';
import ExternalLink from './external-link';

import logo from '../images/footer_logo.svg';
import '../styles/footer.scss';
import { MAIN_CRAVATH_SITE } from '../utils/constants';

const Footer = () => (
    <>
        <PreFooter />
        <footer className="footer background-darkBlue">
            <div className="white footer__content">
                <ExternalLink
                    to="mailto:cravath200@cravath.com"
                    className="yellow u-uppercase u-hover-icon-wiggle type-very-small"
                >
                    Share your story <span className="icon icon-arrow footer__left-link-icon" />
                </ExternalLink>

                <ExternalLink
                    to={MAIN_CRAVATH_SITE}
                    newTab
                    className="type-super-small u-text-center-mobile-text-right-tablet"
                >
                    <img alt="Cravath Logo" src={logo} className="footer__logo" />
                    <br />
                    &copy; {new Date().getFullYear()} Cravath, Swaine &amp; Moore LLP
                </ExternalLink>
            </div>
        </footer>
    </>
);

export default Footer;

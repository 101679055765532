import * as React from 'react';

const rotationSpeed = 0.3;
const finalRotation = 30;

class HomepageBackgroundGradient extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rotation: finalRotation,
            reverse: false,
            wipePercent: 100,
        };
        this.requestId = 0;
    }

    componentDidMount() {
        this.startWipe();
    }

    startWipe() {
        this.requestId = requestAnimationFrame(this.wipe);
    }

    startGradientRotation() {
        this.setState({ rotation: finalRotation });
        this.requestId = requestAnimationFrame(this.tick);
    }

    componentWillUnmount() {
        cancelAnimationFrame(this.requestId);
    }

    wipe = () => {
        let { wipePercent } = this.state;
        if (wipePercent > 0) {
            wipePercent -= 1;
            this.setState({ wipePercent });
            this.requestId = requestAnimationFrame(this.wipe);
        } else {
            this.startGradientRotation();
        }
    };

    tick = () => {
        let { rotation, reverse } = this.state;
        if (reverse) {
            rotation -= rotationSpeed;
            reverse = rotation <= -15 ? !reverse : reverse;
        } else {
            rotation += rotationSpeed;
            reverse = rotation >= finalRotation ? !reverse : reverse;
        }

        this.setState({ rotation, reverse });
        this.requestId = requestAnimationFrame(this.tick);
    };

    render() {
        return (
            <>
                <div
                    className="homepage__background-item homepage__background-gradient"
                    style={{
                        background: `linear-gradient(${
                            this.state.rotation
                        }deg, #021231 35%, rgba(2, 18, 49, 0.15) 100%)`,
                    }}
                />
                <div
                    className="homepage__background-item homepage__background-wipe"
                    style={{
                        background: `linear-gradient(75deg, #021231 ${
                            this.state.wipePercent
                        }%, rgba(2, 18, 49, 0.15) 100%)`,
                    }}
                />
            </>
        );
    }
}

export default HomepageBackgroundGradient;

import React from 'react';

const ModuleFooting = ({ footing }) => {
    if (footing) {
        return <p className="type-very-small lightBlue u-uppercase">{footing}</p>;
    }
    return '';
};

export default ModuleFooting;
